import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
// import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/layout'
import { FaHeart, FaComment } from 'react-icons/fa'
import { navigate } from 'gatsby'

import { styles } from '../styles/styles.jsx'

const Posts = ({ data }) => (
  <Layout>
    <BackgroundImage
      className=""
      fluid={data.datoCmsPostsPage.backgroundImage.fluid}
    >
      <div className={styles.featuredBgOverlay} />
      <div className={styles.container}>
        <div className={styles.featuredContainer}>
          <h2 className={styles.featuredTag}>{data.datoCmsPostsPage.headline}</h2>
          <h1 className={styles.featuredTitle}>
            Instablog
          </h1>
          <p style={{color: '#fff'}}>
            {data.datoCmsPostsPage.description}
          </p>
          <span
            className={styles.featuredLink}
            style={{ borderWidth: '1px', cursor: 'pointer', maxWidth: '100px', textAlign: 'center' }}
            onClick={() =>
              navigate(`https://www.instagram.com/monxdh`)
            }
          >
            Follow
          </span>
        </div>
      </div>
    </BackgroundImage>
    <div className={styles.container}>
      <div className={styles.instaPostsWrapper}>
        {data.allInstaNode.edges.map((post) => (
          <div className={styles.instaPostContainer}>
            <div className={styles.instaPostInnerWrapper}>
              <Image fluid={post.node.localFile.childImageSharp.fluid} />
              <div className={styles.instaPostMetaWrapper} style={{ bottom: 0 }}>
                <div className={styles.instaPostMetaInner} style={{ backgroundColor: `rgb(198 210 126 / 50%)` }}>
                  <div className={styles.instaPostInfoWrapper}>
                    {post.node.likes !== null &&
                      <>
                        <FaHeart
                          size={'1rem'}
                          style={{ color: '#fff', marginTop: 'auto', marginBottom: 'auto', marginRight: '3px' }}
                        />
                        <div className={styles.instaPostLikes}>{post.node.likes}</div>
                      </>
                    }
                    {post.node.comments !== null &&
                      <>
                        <FaComment
                          size={'1rem'}
                          style={{ color: '#fff', marginTop: 'auto', marginBottom: 'auto', marginRight: '3px' }}
                        />
                        <div className={styles.instaPostComments}>{post.node.comments}</div>
                      </>
                    }
                  </div>
                  <h2 className={styles.instaPostCaption} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <a href={`https://instagram.com/p/` + post.node.id} style={{textDecoration: 'none', color: 'white'}}>

                    {post.node.caption}
                    </a>

                    </h2>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
    </div>
  </Layout>
)

export default Posts

export const postsQuery = graphql`
  query instagramPostsQuery {
    allInstaNode(limit: 30, sort: {fields: timestamp, order: DESC}) {
      edges {
        node {
          id
          timestamp
          caption
          likes
          comments
          thumbnails {
            src
          }
          preview
          localFile {
            childImageSharp {
              fluid(maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
    datoCmsPostsPage {
      id
      backgroundImage {
        fluid(maxWidth: 1080) {
          ...GatsbyDatoCmsFluid
        }
      }
      headline
      description
    }
  }
`
